export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.hook("vuetify:configuration", vuetify => {
    vuetify.vuetifyOptions = vuetify.vuetifyOptions || {};
    vuetify.moduleOptions = vuetify.moduleOptions || { styles: { configFile: "./styles/variables.scss" } };
    Object.assign(vuetify.vuetifyOptions, {
      theme: {
        defaultTheme: "light",
        themes: {
          light: {
            dark: false,
            colors: {
              background: "#f7f2f3", // for background color ---ff8000
              surface: "#FFFFFF", // for card color
              primary: "#FF0000",
              redColor: "#fd060f",
              yellowColor: "#ffaa009e",
              reviewBonusBg: "#fff2f5",
              reputation: "#26c280",
              brownTitles: "#332426",
              rating: "#ffaa009e",
              linkColor: "#f34",
              textButton: "#f34",
              jackpot: "#26c280",
              button: "#FF0000",
              buttonBackground: "#FF0000",
              iconColor: "#FF0000",
              toTop: "ffaa009e",
            },
          },
          dark: {},
        },
      },
    });
  });
});
